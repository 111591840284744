<template>
  <div class="process-container">
    <div class="tip">填写的信息仅完成本次认证，平台方不会留存或泄漏</div>
    <div class="process-content">
      <div class="process-one">
        <span :class="active == 2 ? 'acive-num-success' : 'acive-num'">
          <img v-if="active == 2" src="@/assets/imgs/hook.svg" alt="" />
          <span v-else>1</span>
        </span>
        <span :class="active == 2 ? 'acive-text-success' : 'acive-text'"
          >确认企业信息</span
        >
      </div>
      <div class="line"></div>
      <div class="process-two">
        <span :class="active == 2 ? 'acive-num' : 'default-num'">2</span>
        <span :class="active == 2 ? 'acive-text' : 'default-text'"
          >认证授权</span
        >
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['active'],
    data() {
      return {}
    },
  }
</script>

<style lang="less" scoped>
  .process-container {
    padding: 16px;
    .tip {
      font-size: 13px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #333333;
      line-height: 18px;
      margin-bottom: 16px;
    }
    .process-content {
      display: flex;
      justify-content: space-around;
      padding: 0px 44px;
    }
    .process-one,
    .process-two {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .acive-num,
    .default-num,
    .acive-num-success {
      width: 36px;
      height: 36px;
      border-radius: 18px;
      display: inline-block;
      background: #1676ff;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      line-height: 36px;
      color: #ffffff;
      margin-bottom: 8px;
    }
    .acive-text,
    .default-text,
    .acive-text-success {
      font-size: 12px;
      color: #1f2023;
    }
    .acive-text {
      font-weight: 500;
    }
    .default-num {
      border: 1px solid #dadce0;
      background: #fff;
      color: #afb1b3;
    }
    .default-text {
      color: #afb1b3;
    }
    .acive-num-success {
      border: 1px solid #1676ff;
      background: #fff;
    }
    .line {
      height: 1px;
      flex: 1;
      border-bottom: 1px dashed #1676ff;
      margin-top: 20px;
    }
  }
</style>
