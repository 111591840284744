<template>
  <div class="auth-submit-container">
    <HeadProcess active="1" />
    <div class="spacing"></div>
    <div class="content">
      <p
        v-if="createAuthStatus() != 6 && createAuthStatus() != 0"
        :class="createAuthStatus() == 3 ? 'hint-success' : 'hint-error'"
      >
        <img
          v-if="createAuthStatus() == 2 || createAuthStatus() == 1"
          src="@/assets/imgs/ic_result_wrong@2x.png"
          alt=""
        />
        <img
          v-if="createAuthStatus() == 3"
          src="@/assets/imgs/ic_result_pass@2x.png"
          alt=""
        />
        <span> {{ formTitle }}</span>
      </p>
      <!-- <p v-if="userAuthInfo.creditStatusSelf == 2" class="hint-error">
        <img src="@/assets/imgs/ic_result_wrong@2x.png" alt="" />
        <span> 营业执照信息有误，请重新提交</span>
      </p>
      <p v-if="userAuthInfo.creditStatusSelf == 3" class="hint-success">
        <img src="@/assets/imgs/ic_result_pass@2x.png" alt="" />
        <span> 已通过基本信息认证</span>
      </p> -->
      <!-- <div class="item-input-box">
        <p class="title">企业名称</p>
        <van-field
          :border="true"
          placeholder="请输入企业名称（不超过24个字）"
          :value="userAuthInfo.comName"
        />
      </div> -->
      <template v-if="createAuthStatus() == 6 && active == 1">
        <div class="item-line">
          请确认您要创建的企业名称，确保与营业执照上的名称一致
        </div>
        <div class="item-input-box">
          <van-field
            v-model="userAuthInfo.comName"
            center
            clearable
            placeholder="请输入企业名称"
          >
            <template #button>
              <van-button
                class="searchBtn"
                size="small"
                type="primary"
                @click="getCompanyList"
                v-if="active == 1"
              >
                查询
              </van-button>
            </template>
          </van-field>
        </div>
        <ul class="list" v-if="list.length > 0">
          <li
            v-for="(item, index) in list"
            :key="index"
            @click="handleRow(item)"
          >
            <div class="name" v-html="item.html">{{ item.comName }}</div>
            <div class="tag">工商注册名称</div>
          </li>
        </ul>
      </template>
      <template v-else>
        <div class="item-input-box item-input-box-first">
          <p class="title">
            <span> 企业名称<span style="color:#F15643">*</span> </span>
          </p>
          <van-field
            v-model="userAuthInfo.comName"
            :border="true"
            maxlength="24"
            disabled
            placeholder="输入和营业执照上一致的单位名称"
          />
        </div>
        <div class="item-input-box">
          <p class="title">
            <span> 社会统一信用代码<span style="color:#F15643">*</span> </span>
          </p>
          <van-field
            v-model="userAuthInfo.comCreditCode"
            :border="true"
            placeholder="输入统一社会信用代码/工商注册号"
          />
        </div>
        <div class="item-input-box">
          <p class="title">
            <span> 法定代表人<span style="color:#F15643">*</span> </span>
          </p>
          <van-field
            v-model="userAuthInfo.comLegalName"
            :border="true"
            placeholder="输入法定代表人姓名"
          />
        </div>
        <!-- <div class="item-input-box">
          <p class="title">
            <span> 法人身份证号<span style="color:#F15643">*</span> </span>
          </p>
          <van-field
            v-model="userAuthInfo.comLegalIdNum"
            type="text"
            :border="true"
            placeholder="输入法定代表人身份证号"
          />
        </div> -->
        <div
          v-if="
            createAuthStatus() == 1 ||
              createAuthStatus() == 3 ||
              authErrorStatus
          "
          class="suc-mask"
        ></div>
      </template>
    </div>
    <div v-if="createAuthStatus() != 1" class="button-box">
      <van-button plain type="info" @click="handleBack">取消</van-button>
      <van-button
        v-if="createAuthStatus() == 6 && active == 1"
        type="info"
        @click="handleActive"
        >下一步</van-button
      >
      <van-button
        v-else-if="
          createAuthStatus() == 0 ||
            createAuthStatus() == 6 ||
            (createAuthStatus() == 2 && !authErrorStatus)
        "
        type="info"
        @click="handleConfirm"
        >提交</van-button
      >
      <van-button v-if="createAuthStatus() == 3" type="info" @click="handleNext"
        >继续认证</van-button
      >
      <van-button
        v-if="createAuthStatus() == 2 && authErrorStatus"
        type="info"
        @click="handleEdit"
        >修改</van-button
      >
    </div>
  </div>
</template>

<script>
  import Apis from '@/api/creadit'
  import Apifiles from '@/api/files'
  import { mapState, mapGetters } from 'vuex'
  import { Toast } from 'vant'
  import HeadProcess from './Components/headProcess.vue'

  export default {
    components: {
      HeadProcess,
    },
    data() {
      return {
        // 授权信息
        userAuthInfo: {
          comName: '',
          comCreditCode: '',
          comLegalName: '',
          // comLegalIdNum: '',
        },
        // picker
        pickerPopupShow: false,
        // 行业类型
        industryList: [],
        // picker传入list
        list: [],
        // 上传文件-营业执照
        fileList: [],
        // picker默认选中
        defalutOptions: 0,
        authErrorStatus: false,
        active: 1,
        comId: null,
      }
    },
    async created() {
      if (this.$route.query.comId) {
        this.comId = this.$route.query.comId || null
      }
      // 获取企业认证信息
      await this.getComAuthInfo()
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
      formTitle() {
        const status = this.createAuthStatus()
        let text = ''
        switch (status) {
          case 1:
            text =
              '由于您输入的信息与识别信息不符，已提交人工审核，预计1小时内（非节假日9:30-19:00）为您返回审核结果'
            break
          case 2:
            text = '营业执照信息有误，请重新提交'
            break
          case 3:
            text = '已通过基本信息认证'
            break
          default:
            text = ''
        }
        return text
      },
    },

    methods: {
      // 获取企业认证信息
      getComAuthInfo() {
        return Apis.getComAuthInfo({
          comId: this.comId || this.userInfo.comId,
        }).then(res => {
          this.userAuthInfo = { ...res }
          if (this.createAuthStatus() === 4 || this.createAuthStatus() === 5) {
            this.$router.replace({
              name: 'AuthConfirm',
              query: {
                comId: this.comId || null,
              },
            })
            return
          }
          if (this.createAuthStatus() === 2) {
            this.authErrorStatus = true
          }
          if (res.picKey) {
            this.fileList = [
              {
                url: res.picKey,
              },
            ]
          } else {
            this.fileList = []
          }
        })
      },
      createAuthStatus() {
        // creditStatusSelf 0.营业执照识别未提交 1.营业执照待审核 2.营业执照审核拒绝 3.企业三方认证成功 4.选择线上签署页面 5.选择线下签署页面 6.未认证
        return this.userAuthInfo.creditStatusSelf
      },
      // 获取行业类型
      getCompanyList() {
        Apis.getCompanyList({ keyword: this.userAuthInfo.comName }).then(
          res => {
            res.rowsList.forEach(e => {
              e.html = e.comName
                .split(this.userAuthInfo.comName)
                .join(
                  `<span style="color: #1676ff;">${this.userAuthInfo.comName}</span>`
                )
            })
            this.list = res.rowsList
          }
        )
      },
      // 提交认证信息
      handleConfirm() {
        const errMessage = this.validate(this.userAuthInfo)
        if (errMessage) {
          Toast(errMessage)
          return
        }
        const confirmObj = {
          comCreditCode: this.userAuthInfo.comCreditCode,
          comId: this.comId || this.userInfo.comId,
          // comLegalIdNum: this.userAuthInfo.comLegalIdNum,
          comLegalName: this.userAuthInfo.comLegalName,
          comName: this.userAuthInfo.comName,
        }
        Apis.addComBasicInfo(confirmObj).then(res => {
          if (res.status === 200) {
            Toast('提交成功')
            setTimeout(async () => {
              await this.getComAuthInfo()
            }, 1000)
          } else if (res.status === 201) {
            const { data, msg } = res
            if (data) {
              this.errData.comNameStatus = data?.comNameStatus
              this.errData.comNoStatus = data?.comNoStatus
              this.errData.idCardStatus = data?.idCardStatus
              this.errData.nameStatus = data?.nameStatus
              console.log(this.errData)
            } else {
              this.$toast(msg)
            }
          }
        })
      },

      validate(obj) {
        if (!obj.comCreditCode) {
          return '统一社会信用代码不能为空'
        }
        // if (!obj.comLegalIdNum) {
        //   return '法人身份证号不能为空'
        // }
        if (!obj.comLegalName) {
          return '法人姓名不能为空'
        }
        if (!obj.comName) {
          return '企业名称不能为空'
        }
        return false
      },

      // 修改
      handleEdit() {
        this.authErrorStatus = false
      },

      // 继续认证
      handleNext() {
        this.$router.replace({
          name: 'AuthConfirm',
          query: {
            comId: this.comId || null,
          },
        })
      },

      // 返回
      handleBack() {
        this.$router.back()
      },
      handleRow(row) {
        this.userAuthInfo = Object.assign(this.userAuthInfo, row)
      },
      handleActive() {
        if (!this.userAuthInfo.comCreditCode) {
          this.$toast('请选择企业')
          return
        }
        this.active = 2
      },
    },
  }
</script>

<style lang="less" scoped>
  .auth-submit-container {
    padding-bottom: 66px;
    .content {
      padding: 24px 16px 16px;
      position: relative;
      .list {
        height: calc(100vh - 310px);
        overflow-y: auto;
        li {
          height: 52px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 0.5px solid #eeeeee;
          .name {
            font-size: 15px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #111a34;
            line-height: 21px;
            letter-spacing: 0.26px;
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
              color: #1676ff;
            }
          }
          .tag {
            width: 95px;
            height: 21px;
            background: #f5f5f5;
            border-radius: 2px;
            font-size: 13px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: center;
            color: #999999;
            line-height: 21px;
            letter-spacing: 0.22px;
          }
        }
      }
      .suc-mask {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .hint-success,
      .hint-error {
        font-size: 14px;
        font-weight: 500;
        color: #16bb83;
        padding-bottom: 24px;
        img {
          width: 14px;
          height: 14px;
        }
      }
      .hint-error {
        color: #f15643;
      }
      .item-input-box {
        .searchBtn {
          font-size: 14px;
          width: 90px;
          height: 32px;
        }
        .errorData {
          font-size: 12px;
          line-height: 12px;
          padding-top: 6px;
          color: #f15643;
        }
        /deep/.van-field__control {
          color: #323233 !important;
        }
        .title {
          font-size: 14px;
          font-weight: 400;
          color: #111a34;
          line-height: 22px;
          padding: 0;
          display: flex;
          justify-content: space-between;
          margin: 20px 0 0 0;
          .right {
            font-size: 13px;
            color: #1676ff;
          }
        }
        .title-margin {
          margin: 16px 0;
        }
        .van-cell {
          padding: 0;
          display: flex;
          align-items: center;
          box-shadow: 0px -1px 0px 0px #f5f5f5 inset;
        }
        /deep/.van-field__body {
          height: 44px;
          .van-field__control:disabled {
            -webkit-text-fill-color: #323233 !important;
          }
        }
        .update-box {
          display: flex;

          .left {
            width: 104px;
            height: 136px;
            background: #f6f9ff;
            font-size: 14px;
            color: #677283;
            /deep/.van-ellipsis {
              width: 104px;
              height: 136px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              img {
                width: 34px;
                height: 34px;
              }
            }
            /deep/.van-uploader__preview {
              width: 104px;
              height: 136px;
              margin: 0;
            }
            /deep/.van-image {
              width: 104px;
              height: 136px;
            }
          }
          .right {
            font-size: 13px;
            font-family: PingFangSC, PingFangSC-Regular;
            color: #404c56;
            line-height: 20px;
            height: 136px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 16px;
            .update-title {
              font-size: 13px;
              color: #111a34;
              margin-bottom: 9px;
            }
          }
        }
      }
      .item-input-box-first {
        .title {
          margin: 0;
        }
      }
      .item-line {
        font-size: 13px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 18px;
        margin-bottom: 16px;
      }
    }
    .spacing {
      height: 8px;
      background: #f4f5f6;
    }
    .button-box {
      position: fixed;
      bottom: @safe-area;
      width: 100%;
      box-shadow: 0 -6px 10px 0 rgba(230, 234, 237, 0.3);
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: @WHITE;
      .van-button {
        width: 48%;
        height: 44px;
        border-radius: 4px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .block {
      width: 274px;
      height: 370px;
      background-color: #fff;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .examplesClose {
      position: absolute;
      right: 0;
      top: -23px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
    }
  }
</style>
